import { createClient } from '@supabase/supabase-js';
import { getStore } from './storeInjector';
import { loginDirect, updateAccessToken, setCredentials } from '../store/authSlice';
import { logout as logoutApi} from '../api/auth';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

// console.log('Supabase URL:', supabaseUrl);
// console.log('Environment:', process.env.NODE_ENV);

if (!supabaseUrl || !supabaseAnonKey) {
    console.error('Missing Supabase environment variables:', {
        url: !!supabaseUrl,
        key: !!supabaseAnonKey
    });
    throw new Error('Missing Supabase environment variables');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

supabase.auth.onAuthStateChange((event, session) => {
    const store = getStore();
    
    switch (event) {
        case 'INITIAL_SESSION':
            break;

        case 'SIGNED_IN':
            // Defer session handling to avoid blocking the callback
            if(session){
                setTimeout(async () => {
                  await store.dispatch(setCredentials({
                    session: session
                  }));
                  // const isActive = await checkSessionStatus();
                  // if(isActive){
                  //     console.log('SupabaseClient: session already in state');
                  //     return;
                  // }
                  if (session) {
                      store.dispatch(loginDirect(session));
                  }
                }, 0);
            }
            break;

        case 'SIGNED_OUT':
            // Clean up local storage and state
            setTimeout(() => {
                console.log('SupabaseClient: signed out');
                logoutApi({cleanupOnly: true});
            }, 0);
            break;

        case 'TOKEN_REFRESHED':
            // Update the in-memory access token and store
            if (session?.access_token) {
                store.dispatch(setCredentials({
                    session: session
                }));
                // setTimeout(() => {
                //     refreshTokenOnServer();
                // }, 0);
            }
            break;

        case 'USER_UPDATED':
            // Update user profile information
            setTimeout(() => {
                if (session) {
                    store.dispatch(updateAccessToken({ 
                        accessToken: session.access_token, 
                        expiresAt: session.expires_at || null 
                    }));
                }
            }, 0);
            break;
    }
});

