import { axiosInstance } from './auth';
import type { Chat, Message } from '../types/types';
import { createParser, type ParsedEvent, type ReconnectInterval } from 'eventsource-parser';
import { CONFIG } from '../config';
import { getStore } from '../utils/storeInjector';


const BASE_URL = CONFIG.API_URL;
/**
 * Sends a message to the chat API and streams the response.
 * @param message - The message to send
 * @param model - The AI model to use
 * @param chatId - The ID of the chat
 * @param onChunk - Callback function to handle each chunk of the response
 * @param signal - Optional AbortSignal to cancel the request
 * @returns The updated Chat object if available
 * @throws Will throw an error if the request fails or the server returns an error
 */

export const sendMessage = async (
  message: string,
  model: string,
  chatId: string,
  stream: boolean,
  overrideCreditCheck: boolean,
  retryFlag: boolean,
  onChunk: (chunk: string) => void,
  abortSignal?: AbortSignal
): Promise<{ chat: Chat, generationMetadata: any, userMessageId: string, aiMessageId: string, isTrimmed: boolean }> => {
  console.log(`Sending message: ${message} Model: ${model} ChatId: ${chatId}`);

  try {
    const token = getStore().getState().auth.accessToken;
    const response = await fetch(`${BASE_URL}/chats/send`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ message, model, chatId, stream, overrideCreditCheck, retryFlag }),
      signal: abortSignal,
    });

    if (!response.ok) {
      // Attempt to parse error message from the response
      let errorMsg = `HTTP error! status: ${response.status}`;
      try {
        const errorData = await response.json();
        if (errorData.error) {
          errorMsg = errorData.error;
        }
      } catch (e) {
        console.error('Error parsing error response:', e);
      }

      if (response.status === 413) {
        throw new Error('Context limit exceeded. Please start a new conversation or remove some messages.');
      }
      throw new Error(errorMsg);
    }

    let generationMetadata: any = null;
    let userMessageId: string = '';
    let aiMessageId: string = '';
    let isTrimmed: boolean = false;

    if (!stream) {
      // Handle non-streaming response
      const responseData = await response.json();
      onChunk(responseData.content);
      generationMetadata = responseData.generationMetadata;
      userMessageId = responseData.userMessageId;
      aiMessageId = responseData.aiMessageId;
      isTrimmed = responseData.isTrimmed;
      return {
        chat: responseData.updatedChat ? {
          ...responseData.updatedChat,
          totalTokenCount: responseData.updatedChat.totalTokenCount || undefined,
          totalChatCost: responseData.updatedChat.totalChatCost || undefined,
          updatedAt: responseData.updatedChat.updatedAt ? new Date(responseData.updatedChat.updatedAt) : undefined,
        } : null,
        generationMetadata,
        userMessageId,
        aiMessageId,
        isTrimmed
      };
    }

    // Handle streaming response
    const reader = response.body?.getReader();
    if (!reader) {
      throw new Error('Failed to obtain reader from response body.');
    }

    const decoder = new TextDecoder();
    let updatedChat: Chat | null = null;

    const parser = createParser((event: ParsedEvent | ReconnectInterval) => {
      if (event.type === 'event') {
        if (event.data === '[DONE]') {
          console.log('sendMessage: Stream done.');
          return;
        }
        try {
          const parsed = JSON.parse(event.data);
          //console.log('sendMessage: Parsed data:', parsed);
          if (parsed.done && parsed.updatedChat) {
            updatedChat = {
              ...parsed.updatedChat,
              totalTokenCount: parsed.updatedChat.totalTokenCount || undefined,
              totalChatCost: parsed.updatedChat.totalChatCost || undefined,
              updatedAt: parsed.updatedChat.updatedAt ? new Date(parsed.updatedChat.updatedAt) : undefined,
            };
            generationMetadata = parsed.generationMetadata;
            userMessageId = parsed.userMessageId;
            aiMessageId = parsed.aiMessageId;
            isTrimmed = parsed.isTrimmed;
          } else if (parsed.content) {
            onChunk(parsed.content);
          } else if (parsed.error) {
            console.error('sendMessage: Server-side error:', parsed.error);
            throw new Error(parsed.error);
          }
        } catch (e) {
          console.error('sendMessage: Error parsing SSE data:', e);
          console.error('Raw SSE data:', event.data);
          if (event.data.includes('"error"')) {
            try {
              const errorObj = JSON.parse(event.data);
              throw new Error(errorObj.error || 'Unknown server error');
            } catch (parseError) {
              throw new Error('Unknown server error');
            }
          }
        }
      } else if (event.type === 'reconnect-interval') {
        console.log(`sendMessage: Reconnect interval set to ${event.value} ms`);
        // Handle reconnect interval if necessary
      }
    });

    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        break;
      }
      const chunk = decoder.decode(value, { stream: true });
      parser.feed(chunk);
    }

    return { chat: updatedChat!, generationMetadata, userMessageId, aiMessageId, isTrimmed };
  } catch (error) {
    if (error instanceof Error && error.name !== "AbortError") {
      console.error('Error sending message:', error);
    }
    throw error;
  }
};


export const createChat = async (title: string) => {
  console.log('createChat: Creating chat with title:', title);
  const response = await axiosInstance.post('/chats/new', { title });
  const chat = response.data;
  //console.log('createChat: Chat created:', chat);
  return {
    ...chat,
    createdAt: chat.createdAt ? new Date(chat.createdAt).toISOString() : '',
    updatedAt: chat.updatedAt ? new Date(chat.updatedAt).toISOString() : '',
    messages: chat.messages || [],
  };
};


export const getAllChats = async (): Promise<Chat[]> => {
  const response = await axiosInstance.get('/chats');
  const chats = response.data;
  const processedChats = chats.map((chat: Chat) => ({
    ...chat,
    tags: chat.tags || undefined,
    folderId: chat.folderId || undefined,
    totalTokenCount: chat.totalTokenCount || undefined,
    totalChatCost: chat.totalChatCost || undefined,
    createdAt: chat.createdAt ? new Date(chat.createdAt).toISOString() : '',
    updatedAt: chat.updatedAt ? new Date(chat.updatedAt).toISOString() : '',
    messages: chat.messages || [],
    lastMessage: chat.messages && chat.messages.length > 0 ? chat.messages[chat.messages.length - 1] : null,
    lastMessageTime: chat.updatedAt ? new Date(chat.updatedAt).toISOString() : '',
  })) as Chat[];

  return processedChats.sort((a, b) => {
    const timeA = a.lastMessageTime ? new Date(a.lastMessageTime).getTime() : 0;
    const timeB = b.lastMessageTime ? new Date(b.lastMessageTime).getTime() : 0;
    return timeB - timeA;
  });
};

export const getChat = async (chatId: string) => {
  const response = await axiosInstance.get(`/chats/${chatId}`);
  const chat = response.data;
  return {
    ...chat,
    tags: chat.tags || undefined,
    folderId: chat.folderId || undefined,
    totalTokenCount: chat.totalTokenCount || undefined,
    totalChatCost: chat.totalChatCost || undefined,
    createdAt: chat.createdAt ? new Date(chat.createdAt).toISOString() : '',
    updatedAt: chat.updatedAt ? new Date(chat.updatedAt).toISOString() : '',
    messages: chat.messages || [],
  } as Chat;
};

export const getChatMessages = async (chatId: string) => {
  const response = await axiosInstance.get(`/chats/${chatId}/messages`);
  return response.data.map((message: any) => ({
    _id: message._id,
    senderId: message.senderId,
    toModelId: message.toModelId,
    contentType: message.contentType,
    content: message.content,
    isUser: message.isUser !== undefined ? message.isUser : message.senderId === 'user',
    attachments: message.attachments,
    isEdited: message.isEdited,
    generationId: message.generationId,
    CalculatedTokenCount: message.CalculatedTokenCount,
    tokensPrompt: message.tokensPrompt,
    tokensCompletion: message.tokensCompletion,
    totalMessageCost: message.totalMessageCost,
    voiceInput: message.voiceInput,
    createdAt: message.createdAt ? new Date(message.createdAt).toISOString() : '',
    updatedAt: message.updatedAt ? new Date(message.updatedAt).toISOString() : '',
  })) as Message[];
};


export const deleteChat = async (chatId: string) => {
  const response = await axiosInstance.delete(`/chats/${chatId}`);
  return response.data;
};


export const renameChat = async (chatId: string, title: string) => {
  const response = await axiosInstance.put(`/chats/${chatId}/rename`, { title });
  return response.data;
};

export const deleteMessage = async (chatId: string, messageId: string, retryFlag: boolean) => {
  console.log("delete message params: chatId:", chatId);
  console.log("delete message params: messageId:", messageId);
  console.log("delete message params: retryFlag:", retryFlag);
  const response = await axiosInstance.delete(`/chats/${chatId}/messages/${messageId}`, { params: { chatId, messageId, retryFlag } });
  return response.data;
};
