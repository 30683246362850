import { AppDispatch } from '../store/store';
import { setActiveStream } from '../store/uiSlice';

// Create a singleton service to manage abort controllers
class StreamingService {
    private abortControllers: { [chatId: string]: AbortController } = {};
    
    addAbortController(chatId: string, controller: AbortController) {
      this.abortControllers[chatId] = controller;
    }
    
    removeAbortController(chatId: string) {
      delete this.abortControllers[chatId];
    }
    
    cancelStream(chatId: string, dispatch: AppDispatch) {
      const controller = this.abortControllers[chatId];
      if (controller) {
        controller.abort();
        delete this.abortControllers[chatId];
        dispatch(setActiveStream({ chatId, isStreaming: false }));
        console.log(`Stream for chatId ${chatId} has been cancelled.`);
      }
    }
  }
  
  export const streamingService = new StreamingService();