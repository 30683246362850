import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Chat as ChatOriginal,
  Message as MessageOriginal,
  MessageSenderType,
} from "../types/types";
import { RootState } from "./store";
import { createChat, sendMessage, deleteMessage } from "../api/chats";
import {
  setActiveStream,
  setChatLoading,
  setCurrentContextUsage,
} from "./uiSlice";
import {
  calculateContextUsage,
  createNewAIMessage,
  createNewUserMessage,
  updateAIMessageWithMetadata,
  sanitizeInput,
} from "../utils/utils";
import { setSelectedModel } from "./modelSlice";
import { getStore } from "../utils/storeInjector";
import { streamingService } from '../services/streamingService';
import { handleSendError } from '../services/errorHandler';
import { AppDispatch } from './store'; // Import AppDispatch

// Define enhanced types
interface Message extends MessageOriginal {}

interface Chat extends ChatOriginal {
  messages: Message[];
}

// Define the shape of the chat state
interface ChatState {
  chats: Chat[];
  selectedChatId: string | null;
}

const initialState: ChatState = {
  chats: [],
  selectedChatId: null,
};

// Add a helper function to ensure dates are serialized
const serializeDate = (date: Date | string | undefined): string | undefined => {
  if (date instanceof Date) {
    return date.toISOString();
  } else if (typeof date === 'string') {
    return new Date(date).toISOString();
  }
  return undefined;
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    /**
     * Sets the entire list of chats.
     * Normalizes chats and initializes their message states.
     */
    setChats: (state, action: PayloadAction<ChatOriginal[]>) => {
      //console.log('setChats payload:', action.payload);
      state.chats = action.payload.map((chat) => ({
        ...chat,
        isStreaming: false,
        createdAt: chat.createdAt || new Date().toISOString(),
        updatedAt: chat.updatedAt || new Date().toISOString(),
        lastMessageTime: chat.lastMessageTime || undefined,
        messages: chat.messages || [],
      }));
      //console.log('setChats state.chats:', state.chats);
    },

    /**
     * Sets the selected chat by its ID.
     */
    setSelectedChatId: (state, action: PayloadAction<string | null>) => {
      state.selectedChatId = action.payload;
    },

    /**
     * Adds a new chat to the state.
     */
    addChat: (state, action: PayloadAction<ChatOriginal>) => {
      const newChat: Chat = {
        ...action.payload,
        isStreaming: false,
        createdAt: serializeDate(action.payload.createdAt) || new Date().toISOString(),
        updatedAt: serializeDate(action.payload.updatedAt) || new Date().toISOString(),
        lastMessageTime: serializeDate(action.payload.lastMessageTime) || "",
        messages: action.payload.messages || [],
      };
      state.chats.unshift(newChat);
    },

    /**
     * Updates an existing chat.
     * Retains the `isStreaming` status and updates timestamps.
     */
    updateChat: (
      state,
      action: PayloadAction<Omit<ChatOriginal, "messages">>
    ) => {
      const index = state.chats.findIndex(
        (chat) => chat._id === action.payload._id
      );
      if (index !== -1) {
        const existingChat = state.chats[index];
        state.chats[index] = {
          ...existingChat,
          ...action.payload,
          //isStreaming: existingChat.isStreaming || false,
          //createdAt: action.payload.createdAt ? new Date(action.payload.createdAt).toISOString() : existingChat.createdAt,
          updatedAt: action.payload.updatedAt
            ? new Date(action.payload.updatedAt).toISOString()
            : new Date().toISOString(),
          lastMessageTime:
            action.payload.lastMessageTime || existingChat.lastMessageTime,
          //messages: existingChat.messages,
        };
      } else {
        console.error(`Chat with ID ${action.payload._id} not found.`);
      }
    },

    /**
     * Deletes a chat by its ID.
     */
    deleteChat: (state, action: PayloadAction<string>) => {
      const chatExists = state.chats.some(
        (chat) => chat._id === action.payload
      );
      if (chatExists) {
        state.chats = state.chats.filter((chat) => chat._id !== action.payload);
        if (state.selectedChatId === action.payload) {
          state.selectedChatId = null;
        }
      } else {
        console.error(
          `Attempted to delete non-existent chat with ID ${action.payload}.`
        );
      }
    },

    /**
     * Clears the entire chat state to its initial state.
     */
    clearChatState: () => initialState,

    /**
     * Clears all messages from a specific chat.
     */
    clearMessagesInChat: (state, action: PayloadAction<string>) => {
      const chat = state.chats.find((chat) => chat._id === action.payload);
      if (chat) {
        chat.messages = [];
        chat.lastMessageTime = undefined;
        chat.lastMessage = undefined;
        chat.updatedAt = new Date().toISOString();
      } else {
        console.error(
          `Chat with ID ${action.payload} not found when clearing messages.`
        );
      }
    },

    /**
     * Sets all messages for a specific chat.
     */
    setMessagesInChat: (
      state,
      action: PayloadAction<{ chatId: string; messages: Message[] }>
    ) => {
      const { chatId, messages } = action.payload;
      const chat = state.chats.find((chat) => chat._id === chatId);
      if (chat) {
        chat.messages = messages;
        const lastMessage = messages[messages.length - 1];
        if (lastMessage) {
          chat.lastMessageTime = lastMessage.createdAt;
          chat.lastMessage = lastMessage;
        }
        chat.updatedAt = new Date().toISOString();
      } else {
        console.error(
          `Chat with ID ${chatId} not found when setting all messages.`
        );
      }
    },

    /**
     * Adds a message to a specific chat.
     */
    addMessageToChat: (
      state,
      action: PayloadAction<{ chatId: string; message: Message }>
    ) => {
      const { chatId, message } = action.payload;
      const chatIndex = state.chats.findIndex((chat) => chat._id === chatId);
      if (chatIndex !== -1) {
        const chat = state.chats[chatIndex];
        const existingMessage = chat.messages.find(
          (msg) => msg._id === message._id
        );
        if (!existingMessage) {
          chat.messages.push(message);
          chat.lastMessageTime = message.createdAt;
          chat.lastMessage = message;
          chat.updatedAt = new Date().toISOString();

          // Move the updated chat to the top of the chats array
          state.chats.splice(chatIndex, 1);
          state.chats.unshift(chat);
        } else {
          console.warn(
            `addMessageToChat: Message with ID ${message._id} already exists in chat ${chatId}.`
          );
        }
      } else {
        console.error(
          `addMessageToChat: Chat with ID ${chatId} not found when adding a message.`
        );
      }
    },

    /**
     * Updates a message within a specific chat.
     */
    updateMessageInChat: (
      state,
      action: PayloadAction<{ chatId: string; message: Message }>
    ) => {
      const { chatId, message } = action.payload;
      console.log("updateMessageInChat payload:", action.payload);
      const chat = state.chats.find((chat) => chat._id === chatId);
      if (chat) {
        const messageIndex = chat.messages.findIndex(
          (msg) => msg._id === message._id
        );
        if (messageIndex !== -1) {
          chat.messages[messageIndex] = {
            ...chat.messages[messageIndex],
            ...message,
          };

          // Update lastMessage if this is the last message
          if (chat.messages.length - 1 === messageIndex) {
            chat.lastMessageTime = message.createdAt;
            chat.lastMessage = message;
          }

          chat.updatedAt = new Date().toISOString();
        } else {
          console.error(
            `updateMessageInChat: Message with ID ${message._id} not found in chat ${chatId}.`
          );
        }
      } else {
        console.error(
          `updateMessageInChat: Chat with ID ${chatId} not found when updating a message.`
        );
      }
    },

    /**
     * Updates the _id of a message for a specific chat.
     */
    updateMessageId: (
      state,
      action: PayloadAction<{
        chatId: string;
        message: Message;
        newMessageId: string;
      }>
    ) => {
      const { chatId, message, newMessageId } = action.payload;
      const chat = state.chats.find((chat) => chat._id === chatId);
      if (chat) {
        const messageToUpdate = chat.messages.find(
          (msg) => msg._id === message._id
        );
        if (messageToUpdate) {
          messageToUpdate._id = newMessageId;
        }
      }
    },

    /**
     * Initiates a streaming message within a specific chat.
     */
    initiateStreamingMessage: (
      state,
      action: PayloadAction<{ chatId: string; message: Message }>
    ) => {
      const { chatId, message } = action.payload;
      const chat = state.chats.find((chat) => chat._id === chatId);
      if (chat) {
        chat.isStreaming = true;

        const existingMessage = chat.messages.find(
          (msg) => msg._id === message._id
        );
        if (!existingMessage) {
          chat.messages.push({
            ...message,
            content: "",
            isComplete: false,
          });
          chat.lastMessageTime = message.createdAt;
          chat.lastMessage = message;
          chat.updatedAt = new Date().toISOString();
        } else {
          console.warn(
            `initiateStreamingMessage: Message with ID ${message._id} already exists in chat ${chatId}.`
          );
        }
      } else {
        console.error(
          `initiateStreamingMessage: Chat with ID ${chatId} not found when initiating a streaming message.`
        );
      }
    },

    /**
     * Updates the content of a streaming message within a specific chat.
     */
    updateStreamingMessage: (
      state,
      action: PayloadAction<{ chatId: string; content: string }>
    ) => {
      const { chatId, content } = action.payload;
      const chat = state.chats.find((chat) => chat._id === chatId);
      if (chat && chat.messages.length > 0) {
        const lastMessage = chat.messages[chat.messages.length - 1];
        lastMessage.content = content;
      } else {
        console.error(
          `updateStreamingMessage: Chat with ID ${chatId} not found or has no messages when updating streaming message.`
        );
      }
    },

    /**
     * Finalizes a streaming message by setting its final content and marking it as complete.
     */
    finalizeStreamingMessage: (
      state,
      action: PayloadAction<{ chatId: string }>
    ) => {
      const { chatId } = action.payload;
      const chat = state.chats.find((chat) => chat._id === chatId);
      if (chat && chat.messages.length > 0) {
        const lastMessage = chat.messages[chat.messages.length - 1];
        lastMessage.isComplete = true;

        // Update lastMessage
        chat.lastMessage = lastMessage;
        chat.lastMessageTime = lastMessage.createdAt;

        chat.isStreaming = false;
      } else {
        console.error(
          `finalizeStreamingMessage: Chat with ID ${chatId} not found or has no messages when finalizing streaming message.`
        );
      }
    },

    abortStreamingMessage: (
      state,
      action: PayloadAction<{ chatId: string }>
    ) => {
      const { chatId } = action.payload;
      const chat = state.chats.find((chat) => chat._id === chatId);
      if (chat && chat.messages.length > 0) {
        chat.messages.pop();
        chat.isStreaming = false;
        chat.updatedAt = new Date().toISOString();
        chat.lastMessage = chat.messages[chat.messages.length - 1];
        chat.lastMessageTime =
          chat.messages[chat.messages.length - 1].updatedAt;
      }
    },

    updateMessageAsFailed: (
      state,
      action: PayloadAction<{ chatId: string; messageId: string }>
    ) => {
      const { chatId, messageId } = action.payload;
      const chat = state.chats.find((chat) => chat._id === chatId);
      if (chat) {
        const message = chat.messages.find((msg) => msg._id === messageId);
        if (message) {
          message.isFailed = true;
        }
      }
    },
  },
});

// Async thunk for deleting a message
export const deleteMessageThunk = createAsyncThunk(
  "chat/deleteMessage",
  async (
    {
      chatId,
      messageId,
      retryFlag,
    }: { chatId: string; messageId: string; retryFlag: boolean },
    { dispatch }
  ) => {
    try {
      const updatedChat = await deleteMessage(chatId, messageId, retryFlag);
      dispatch(setMessagesInChat({ chatId, messages: updatedChat.messages }));
      console.log("deleteMessageThunk: updatedChat:", updatedChat);

      // After deleting a message, update the selected model based on the last assistant message
      const chatMessages = updatedChat.messages as Message[];
      const lastAssistantMessage = chatMessages
        .filter(msg => msg.senderType === MessageSenderType.ASSISTANT && !msg.isDeleted)
        .pop();
      
      if (lastAssistantMessage) {
        dispatch(setSelectedModel(lastAssistantMessage.toModelId));
      } else {
        // If no assistant messages found, default to first model
        const state = getStore().getState() as RootState;
        dispatch(setSelectedModel(state.model.models[0].id));
      }
      return updatedChat;
    } catch (error) {
      console.error("Error deleting message:", error);
      throw error;
    }
  }
);

export const sendMessageThunk = createAsyncThunk<
  void,
  {
    input: string;
    selectedModel: string;
    streamPreference: boolean;
    overrideCreditCheck: boolean;
    retryFlag: boolean;
  },
  { dispatch: AppDispatch; state: RootState }
>(
  "chat/sendMessage",
  async (
    {
      input,
      selectedModel,
      streamPreference,
      overrideCreditCheck,
      retryFlag,
    },
    { dispatch, getState }
  ) => {
    const state = getState() as RootState;
    let currentChatId = state.chat.selectedChatId || "";

    if (currentChatId === "") {
      try {
        const newChat = await createChat("New Chat");
        const serializedChat = {
          ...newChat,
          createdAt: serializeDate(newChat.createdAt),
          updatedAt: serializeDate(newChat.updatedAt),
          lastMessageTime: serializeDate(newChat.lastMessageTime)
        };
        dispatch(addChat(serializedChat));
        currentChatId = newChat._id;
        dispatch(setSelectedChatId(currentChatId));
      } catch (error) {
        console.error("Error creating new chat:", error);
        handleSendError(error, currentChatId, dispatch);
        dispatch(setChatLoading(false));
        return;
      }
    }

    const sanitizedInput = sanitizeInput(input);
    const newUserMessage = createNewUserMessage(sanitizedInput, selectedModel);

    if(!retryFlag) {
      dispatch(addMessageToChat({ chatId: currentChatId, message: newUserMessage }));
    }

    dispatch(setActiveStream({ chatId: currentChatId, isStreaming: true }));

    const newAIMessage = createNewAIMessage("", selectedModel);
    dispatch(
      initiateStreamingMessage({
        chatId: currentChatId,
        message: newAIMessage,
      })
    );

    const abortController = new AbortController();
    streamingService.addAbortController(currentChatId, abortController);

    try {
      let accumulatedContent = "";

      const response = await sendMessage(
        sanitizedInput,
        selectedModel,
        currentChatId,
        streamPreference,
        overrideCreditCheck,
        retryFlag,
        (chunk: string) => {
          accumulatedContent += chunk;
          dispatch(
            updateStreamingMessage({
              chatId: currentChatId,
              content: accumulatedContent,
            })
          );
        },
        abortController.signal
      );

      if (response) {
        const serializedResponse = {
          ...response,
          chat: response.chat ? {
            ...response.chat,
            createdAt: serializeDate(response.chat.createdAt),
            updatedAt: serializeDate(response.chat.updatedAt),
            lastMessageTime: serializeDate(response.chat.lastMessageTime),
            messages: response.chat.messages.map(msg => ({
              ...msg,
              createdAt: serializeDate(msg.createdAt),
              updatedAt: serializeDate(msg.updatedAt)
            }))
          } : response.chat,
          generationMetadata: response.generationMetadata,
          userMessageId: response.userMessageId,
          aiMessageId: response.aiMessageId
        };

        if(!retryFlag) {
          dispatch(
            updateMessageId({
              chatId: currentChatId,
              message: newUserMessage,
              newMessageId: serializedResponse.userMessageId,
            })
          );
        }

        dispatch(
          updateMessageId({
            chatId: currentChatId,
            message: newAIMessage,
            newMessageId: serializedResponse.aiMessageId,
          })
        );

        let lastAIMessage: Message;
        let lastUserMessage: Message;

        if (serializedResponse.chat) {
          lastAIMessage = serializedResponse.chat.messages[serializedResponse.chat.messages.length - 1];
          if (lastAIMessage.senderType === MessageSenderType.ASSISTANT) {
            dispatch(
              finalizeStreamingMessage({
                chatId: currentChatId,
              })
            );
          }

          lastUserMessage =
            serializedResponse.chat.messages[serializedResponse.chat.messages.length - 2];
          if (lastUserMessage.senderType === MessageSenderType.USER) {
            dispatch(
              updateMessageInChat({
                chatId: currentChatId,
                message: lastUserMessage,
              })
            );
          }

          const updatedMessageWithMetadata = updateAIMessageWithMetadata(
            lastAIMessage,
            serializedResponse.generationMetadata
          );
          dispatch(
            updateMessageInChat({
              chatId: currentChatId,
              message: updatedMessageWithMetadata,
            })
          );

          const contextUsage = calculateContextUsage(
            serializedResponse.chat.messages || []
          );
          dispatch(setCurrentContextUsage(contextUsage));

          if (lastAIMessage.senderId !== selectedModel) {
            dispatch(setSelectedModel(lastAIMessage.senderId));
          }
        }
      }
    } catch (error) {
      if ((error as any).name === "AbortError") {
        console.log(`Stream for chatId ${currentChatId} was cancelled.`);
        dispatch(abortStreamingMessage({ chatId: currentChatId }));
      } else {
        handleSendError(error, currentChatId, dispatch, newUserMessage);
      }
    } finally {
      streamingService.removeAbortController(currentChatId);
      dispatch(setActiveStream({ chatId: currentChatId, isStreaming: false }));
      dispatch(setChatLoading(false));
    }
  }
);

// Add new action for copying message
export const copyMessageToClipboard = createAsyncThunk(
  "chat/copyMessage",
  async (content: string) => {
    await navigator.clipboard.writeText(content);
    return true;
  }
);

// Add new action for retrying message
export const retryMessage = createAsyncThunk<
  void,
  { messageId: string; chatId: string },
  { dispatch: AppDispatch; state: RootState }
>(
  "chat/retryMessage",
  async (
    { messageId, chatId },
    { dispatch, getState }
  ) => {
    const state = getState() as RootState;
    const chat = state.chat.chats.find((c) => c._id === chatId);

    if (!chat) throw new Error("Chat not found");

    const messageIndex = chat.messages.findIndex((m) => m._id === messageId);
    const userMessage = chat.messages[messageIndex - 1];

    if (!userMessage || !userMessage.isUser) {
      throw new Error("Previous user message not found");
    }

    await dispatch(
      deleteMessageThunk({
        chatId,
        messageId,
        retryFlag: true,
      })
    );

    await dispatch(
      sendMessageThunk({
        input: userMessage.content,
        selectedModel: userMessage.toModelId,
        streamPreference: state.ui.streamPreference,
        overrideCreditCheck: false,
        retryFlag: true,
      })
    );

    // Return void explicitly
  }
);

// Export actions
export const {
  setChats,
  setSelectedChatId,
  addChat,
  updateChat,
  deleteChat,
  clearChatState,
  addMessageToChat,
  updateMessageInChat,
  initiateStreamingMessage,
  updateStreamingMessage,
  finalizeStreamingMessage,
  clearMessagesInChat,
  setMessagesInChat,
  updateMessageId,
  updateMessageAsFailed,
  abortStreamingMessage,
} = chatSlice.actions;

// Export selectors for chats and messages
export const selectAllChats = (state: { chat: ChatState }) => state.chat.chats;

export const selectChatById = (state: { chat: ChatState }, id: string) =>
  state.chat.chats.find((chat) => chat._id === id);

export const selectChatIds = (state: { chat: ChatState }) =>
  state.chat.chats.map((chat) => chat._id);

export const selectSelectedChat = (state: { chat: ChatState }) =>
  state.chat.selectedChatId
    ? state.chat.chats.find((chat) => chat._id === state.chat.selectedChatId) ||
      null
    : null;

// Selector for chat messages
export const selectChatMessages = (state: RootState, chatId: string | null) =>
  chatId
    ? state.chat.chats.find((chat) => chat._id === chatId)?.messages || []
    : [];

export default chatSlice.reducer;
