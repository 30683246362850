import { AppDispatch } from '../store/store';
import { Message } from '../types/types';
import { addMessageToChat, updateMessageAsFailed } from '../store/chatSlice';
import { createNewAIMessage } from '../utils/utils';

export const handleSendError = (
  error: any, 
  selectedChatId: string, 
  dispatch: AppDispatch,
  message?: Message
) => {
  console.error('Error in handleSend:', error);
  let errorMessage = 'Error: Unable to get response';
  
  if (error instanceof Error) {
    if (error.message.includes('Context limit exceeded')) {
      errorMessage = 'Context limit exceeded. Please start a new conversation or remove some messages.';
    } else {
      errorMessage = error.message;
    }
  }

  if(message) {
    dispatch(updateMessageAsFailed({ chatId: selectedChatId, messageId: message._id }));
  }

  if (selectedChatId) {
    dispatch(addMessageToChat({ 
      chatId: selectedChatId, 
      message: createNewAIMessage(errorMessage, 'System') 
    }));
  }
  
  alert(errorMessage);
};