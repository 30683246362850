import React, { useMemo } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppWrapper from './AppWrapper';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import CssBaseline from '@mui/material/CssBaseline';
import { useTheme } from './theme/theme';
import { createTheme } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { store } from './store/store';
import ErrorBoundary from './components/ErrorBoundary';

const container = document.getElementById('root');
if (container) {
  const root = ReactDOM.createRoot(container);

  const Main = () => {
    const darkMode = false;
    return (
      <Provider store={store}>
        <ThemeWrapper darkMode={darkMode} />
      </Provider>
    );
  };

  const ThemeWrapper = ({ darkMode }: { darkMode: boolean }) => {
    const { lightTheme, darkTheme } = useTheme();
    const theme = useMemo(() => createTheme(darkMode ? darkTheme : lightTheme), [darkMode, lightTheme, darkTheme]);

    return (
      <MuiThemeProvider theme={theme}>
        <StyledThemeProvider theme={theme}>
          <CssBaseline />
          <AppWrapper />
        </StyledThemeProvider>
      </MuiThemeProvider>
    );
  };

  root.render(
    <React.StrictMode>
      <ErrorBoundary>
        <Main />
      </ErrorBoundary>
    </React.StrictMode>
  );
} else {
  console.error('Root container not found');
}