type ModelProvider = 'openai' | 'anthropic' | 'google' | 'meta-llama';

interface ColorScheme {
  light: {
    base: string;
    step: number;
  };
  dark: {
    base: string;
    step: number;
  };
}

const providerColorSchemes: Record<ModelProvider, ColorScheme> = {
  openai: {
    light: { base: '#2563EB', step: 0.1 },    // Bright blue base
    dark: { base: '#1a2a33', step: 0.1 }
  },
  anthropic: {
    light: { base: '#DC2626', step: 0.1 },    // Bright red base
    dark: { base: '#331a1a', step: 0.1 }
  },
  google: {
    light: { base: '#F97316', step: 0.1 },    // Bright orange base
    dark: { base: '#332b1a', step: 0.1 }
  },
  'meta-llama': {
    light: { base: '#16A34A', step: 0.1 },    // Bright green base
    dark: { base: '#1a331a', step: 0.1 }
  }
};

function adjustColor(color: string, adjustment: number): string {
  // Convert hex to RGB
  const hex = color.replace('#', '');
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  // Darken or lighten
  const newR = Math.max(0, Math.min(255, r - (r * adjustment)));
  const newG = Math.max(0, Math.min(255, g - (g * adjustment)));
  const newB = Math.max(0, Math.min(255, b - (b * adjustment)));

  // Convert back to hex
  return '#' + [newR, newG, newB]
    .map(x => Math.round(x).toString(16).padStart(2, '0'))
    .join('');
}

export function generateModelColors(modelId: string, mode: 'light' | 'dark'): string {
  const provider = modelId.split('/')[0] as ModelProvider;
  const scheme = providerColorSchemes[provider]?.[mode];
  
  if (!scheme) {
    return mode === 'light' ? '#f0f0f0' : '#2a2a2a'; // default color
  }

  // Get variant number from model name (if exists)
  const variantMatch = modelId.match(/\d+/);
  const variantNum = variantMatch ? parseInt(variantMatch[0]) : 0;
  
  // Adjust color based on variant
  return adjustColor(scheme.base, scheme.step * variantNum);
}

export function generateThemeModelColors(models: string[], mode: 'light' | 'dark'): Record<string, string> {
  const colors: Record<string, string> = {
    user: mode === 'light' ? '#000000' : '#ffffff',
  };

  models.forEach(modelId => {
    colors[modelId] = generateModelColors(modelId, mode);
  });

  return colors;
}