export enum MessageSenderType {
  USER = 'user',
  SYSTEM = 'system',
  INTERNAL = 'internal',
  ASSISTANT = 'assistant'
}

export interface Message {
    _id: string;
    content: string;
    isUser: boolean;
    senderId: string;
    senderType: MessageSenderType;
    toModelId: string;
    generationId: string;
    tokensPrompt?: number;
    tokensCompletion?: number;
    totalMessageCost?: number;
    CalculatedTokenCount: number;
    contentType: string;
    attachments?: string[];
    isEdited?: boolean;
    isDeleted?: boolean;
    voiceInput?: boolean;
    createdAt?: string;
    updatedAt?: string;
    isComplete?: boolean;
    isFailed?: boolean;
  };

export interface User {
  _id: string;
  supabaseId: string;
  email: string;
  role: string;
  displayName?: string;
  profilePicture?: string;
  country?: string;
  orgId?: string;
  orgName?: string;
  isParent: boolean;
  modelPermissions: { [key: string]: boolean };
  creditBalance: number;
  settings: {
    theme: string;
    notifications: boolean;
    language: string;
  };
  experiencePoints: number;
  level: number;
  accountStatus: string;
}


export interface Chat {
    _id: string;
    userId: string;
    title: string;
    customTitle: boolean;
    tags?: string[];
    folderId?: string;
    isGroupChat: boolean;
    userParticipants?: {
      userId: string;
      permissions: 'Read' | 'Write' | 'Admin';
    }[];
    messages: Message[];
    totalTokenCount?: number;
    totalChatCost: number;
    createdAt?: string;
    updatedAt?: string;
    lastMessage?: Message;
    lastMessageTime?: string;
    isStreaming?: boolean;
  };


export type ModelConfig = {
    id: string;
    name: string;
    maxOutputTokens: number | null;
    contextWindow: number;
    modality: string;
    tokenizer: string;
    inputTokenCost: string;
    outputTokenCost: string;
    version: number;
    lastUpdated: string;
  };

export interface Notification {
  _id: string;
  userId: string;
  type: 'credit' | 'security' | 'announcement' | 'other' | 'model_update' | 'login';
  title: string;
  content: string;
  isRead: boolean;
  createdAt: string;
  updatedAt: string;
  relatedEntityId?: string;
  relatedEntityType?: string;
}

export const serializeDate = (date: Date | string | undefined): string | undefined => {
  if (date instanceof Date) {
    return date.toISOString();
  } else if (typeof date === 'string') {
    return new Date(date).toISOString();
  }
  return undefined;
};
export const deserializeDate = (dateString: string): Date => new Date(dateString);
